
import {IOrderData} from '.';
import {EventData} from '../core/EventMessageService/types';
import {IMessageRquid, IResponseStatus, IWidgetError, IWidgetInitParams, IWidgetSizes, IWidgetStyle} from '../Models/SharedModels';

/**
 * Системные имена сообщений(возможные варианты).
 *
 * DATA_ERROR_KVK - Ошибка получения данных по КВК.
 * DESTROY - Удаление виджета из DOM.
 * ERROR_STATUS_KVK_DATA - Ошибка получения данных по платежным поручениям.
 * FULL_SCREEN_MODE_DISABLED - Полноэкранный режим не активирован.
 * FULL_SCREEN_MODE_ENABLED - Полноэкранный режим активирован.
 * FULL_SCREEN_MODE_OFF - Выйти из полноэкранного отображения для iframe.
 * FULL_SCREEN_MODE_ON - Отобразить iframe в полный экран.
 * INVALID_SYSTEM_NAME - Ошибка "Передано некорректное значение systemName".
 * KVK_LITE_LIMIT_SUM - Сумма лимита по КВК Лайт.
 * NO_AVAILABLE_POST_MESSAGE_SYSTEM_NAME - Ошибка "Для виджета отсуствует доступный postMessageSystemName".
 * PARTNER_NOT_READY - Сайт партнера не готов(проблемы при отображении iframe)
 * PARTNER_READY - Сайт партнера готов(партнер сообщает когда получил сообщение о готовности виджета).
 * RELOAD - Перезагрузка виджета.
 * REQUEST_DATA_KVK - Запрос данных по КВК.
 * REQUEST_STATUS_KVK_DATA - Данные по платежным поручениям.
 * RESPONSE_DATA_KVK - Передача данных по КВК.
 * SUCCESS_DATA_KVK - Успешное получение данных по КВК.
 * SUCCESS_STATUS_KVK_DATA - Успешное получение данных по платежным поручениям.
 * UPDATE_FRAME_STYLES - Обновить стили фрейма.
 * UPDATE_FRAME_SIZES - Обновить размеров фрейма.
 * WIDGET_READY - Виджет готов(виджет сообщает когда полностью прогружен).
 * SCREEN_SIZE_REQUEST - Запрос размера экрана.
 * SCREEN_SIZE_RESPONSE - Ответ размера экрана.
 */
export enum EPostMessageSystemName {
    DATA_ERROR_KVK = 'DATA_ERROR_KVK',
    DESTROY = 'DESTROY',
    ERROR_STATUS_KVK_DATA = 'ERROR_STATUS_KVK_DATA',
    FULL_SCREEN_MODE_DISABLED = 'FULL_SCREEN_MODE_DISABLED',
    FULL_SCREEN_MODE_ENABLED = 'FULL_SCREEN_MODE_ENABLED',
    FULL_SCREEN_MODE_OFF = 'FULL_SCREEN_MODE_OFF',
    FULL_SCREEN_MODE_ON = 'FULL_SCREEN_MODE_ON',
    INVALID_SYSTEM_NAME = 'INVALID_SYSTEM_NAME',
    KVK_LITE_LIMIT_SUM = 'KVK_LITE_LIMIT_SUM',
    NO_AVAILABLE_POST_MESSAGE_SYSTEM_NAME = 'NO_AVAILABLE_POST_MESSAGE_SYSTEM_NAME',
    PARTNER_NOT_READY = 'PARTNER_NOT_READY',
    PARTNER_READY = 'PARTNER_READY',
    REDIRECT = 'REDIRECT',
    RELOAD = 'RELOAD',
    REQUEST_DATA_KVK = 'REQUEST_DATA_KVK',
    REQUEST_STATUS_KVK_DATA = 'REQUEST_STATUS_KVK_DATA',
    RESPONSE_DATA_KVK = 'RESPONSE_DATA_KVK',
    SUCCESS_DATA_KVK = 'SUCCESS_DATA_KVK',
    SUCCESS_STATUS_KVK_DATA = 'SUCCESS_STATUS_KVK_DATA',
    UPDATE_FRAME_STYLES = 'UPDATE_FRAME_STYLES',
    UPDATE_FRAME_STYLES_RESPONSE = 'UPDATE_FRAME_STYLES_RESPONSE',
    UPDATE_FRAME_SIZES = 'UPDATE_FRAME_SIZES',
    WIDGET_READY = 'WIDGET_READY',
    FULL_SCREEN_MODE = 'FULL_SCREEN_MODE',
    FULL_SCREEN_MODE_RESPONSE = 'FULL_SCREEN_MODE_RESPONSE',
    RENDER_SNAPSHOT = 'RENDER_SNAPSHOT',
    SCREEN_SIZE_REQUEST = 'SCREEN_SIZE_REQUEST',
    SCREEN_SIZE_RESPONSE = 'SCREEN_SIZE_RESPONSE',
}

export type MessageTypes = {
    [EPostMessageSystemName.DATA_ERROR_KVK]: EventData<IWidgetError, IMessageRquid>;
    [EPostMessageSystemName.ERROR_STATUS_KVK_DATA]: EventData<IResponseStatus[], IMessageRquid>;
    [EPostMessageSystemName.FULL_SCREEN_MODE_DISABLED]: EventData<{errorMessage?: string}>;
    [EPostMessageSystemName.FULL_SCREEN_MODE_ENABLED]: EventData<{errorMessage?: string}>;
    [EPostMessageSystemName.INVALID_SYSTEM_NAME]: EventData<IWidgetError>;
    [EPostMessageSystemName.NO_AVAILABLE_POST_MESSAGE_SYSTEM_NAME]: EventData<IWidgetError>;
    [EPostMessageSystemName.PARTNER_READY]: EventData<IWidgetInitParams>;
    [EPostMessageSystemName.REDIRECT]: EventData<{url: string}>;
    [EPostMessageSystemName.REQUEST_DATA_KVK]: EventData<never, IMessageRquid>;
    [EPostMessageSystemName.REQUEST_STATUS_KVK_DATA]: EventData<IResponseStatus[], IMessageRquid>;
    [EPostMessageSystemName.RESPONSE_DATA_KVK]: EventData<IOrderData, IMessageRquid>;
    [EPostMessageSystemName.SUCCESS_DATA_KVK]: EventData<unknown, IMessageRquid>;
    [EPostMessageSystemName.SUCCESS_STATUS_KVK_DATA]: EventData<IResponseStatus[], IMessageRquid>;
    [EPostMessageSystemName.UPDATE_FRAME_STYLES]: EventData<IWidgetStyle>;
    [EPostMessageSystemName.UPDATE_FRAME_STYLES_RESPONSE]: EventData<unknown>;
    [EPostMessageSystemName.UPDATE_FRAME_SIZES]: EventData<IWidgetSizes>;
    [EPostMessageSystemName.WIDGET_READY]: EventData<never>;
    [EPostMessageSystemName.FULL_SCREEN_MODE]: EventData<[boolean, string, string], string>;
    [EPostMessageSystemName.FULL_SCREEN_MODE_RESPONSE]: EventData<boolean, string>;
    [EPostMessageSystemName.RENDER_SNAPSHOT]: EventData<[string, string]>;
    [EPostMessageSystemName.SCREEN_SIZE_REQUEST]: EventData<never, string>;
    [EPostMessageSystemName.SCREEN_SIZE_RESPONSE]: EventData<Screen, string>;
};
